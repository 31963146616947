<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">ホームページデザイン</h1>
        <banner-hint>
            ホームページの印象を決めるヘッダー画像・背景画像、コンテンツの見出しやボタンに使用されるテーマカラーを編集できます。<br />
            ホームページの印象は背景画像によって大きく変わります。必須ではありませんが設定することを強くお薦めします。<br />
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="mb-5 py-7 px-6"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-form ref="form-images">
                <!-- ******************************************** -->
                <!-- ロゴ画像 -->
                <!-- ******************************************** -->
                <num-instruction number="1">
                  HPヘッダーに表示されるロゴ画像を選びます。（各画像1MBが上限サイズです）
                </num-instruction>

                <v-row class="mt-5 mb-10">
                  <!-- ヘッダーロゴ -->
                  <v-col :cols="settingData.header_image_url ? 10 : 12"
                    class="px-7 py-0"
                  >
                    <v-file-input
                      v-model="selectedImages['header_image_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      required
                      prepend-icon="mdi-image"
                      label="ヘッダーロゴ画像１（PC等の横長の画面用）"
                      hint="横x縦の推奨比率 [1.5 : 1] ~ [2 : 1]"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="settingData.header_image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="70"
                      contain
                      :src="settingData.header_image_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="pl-12 py-0 mt-n1 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="settingData.header_image_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('header_image_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row class="my-7">
                  <!-- ヘッダーロゴ - モバイル -->
                  <v-col :cols="settingData.header_image_mobile_url ? 10 : 12"
                    class="px-7 py-0"
                  >
                    <v-file-input
                      v-model="selectedImages['header_image_mobile_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ヘッダーロゴ画像２（スマホ等の縦長の画面用）"
                      hint="推奨比率 [1 : 1] *未設定の場合はロゴ画像1を使用"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="settingData.header_image_mobile_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="70"
                      contain
                      :src="settingData.header_image_mobile_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="pl-12 py-0 mt-n1 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="settingData.header_image_mobile_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('header_image_mobile_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- ヘッダー背景画像 -->
                <num-instruction number="2">
                  HPヘッダーに表示される背景画像を選びます。
                </num-instruction>

                <icon-info icon="alert"
                  class="ml-9"
                >
                  ロゴ画像と違い背景画像はヘッダー全体を覆う様に伸縮するため縦もしくは横のはみ出した部分は表示されません。
                </icon-info>

                <v-row class="mt-5 my-10">
                  <v-col :cols="settingData.header_bg_image_url ? 10 : 12"
                    class="px-7 py-0"
                  >
                    <v-file-input
                      v-model="selectedImages['header_bg_image_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ヘッダー背景画像１（PC等の横長の画面用）"
                      hint="推奨比率 [3 : 1] ~ [4 : 1]"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="settingData.header_bg_image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="70"
                      contain
                      :src="settingData.header_bg_image_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="pl-12 py-0 mt-n1 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="settingData.header_bg_image_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('header_bg_image_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <v-row class="mt-10 my-7">
                  <!-- ヘッダー背景 - モバイル -->
                  <v-col :cols="settingData.header_bg_image_mobile_url ? 10 : 12"
                    class="px-7 py-0"
                  >
                    <v-file-input
                      v-model="selectedImages['header_bg_image_mobile_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="ヘッダー背景画像２（スマホ等の縦長の画面用）"
                      hint="推奨比率 [1 : 1] ~ [1.5 : 1] *未設定の場合はヘッダー背景画像1を使用"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="settingData.header_bg_image_mobile_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="70"
                      contain
                      :src="settingData.header_bg_image_mobile_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="pl-12 py-0 mt-n1 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="settingData.header_bg_image_mobile_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('header_bg_image_mobile_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- サイト背景画像 -->
                <num-instruction number="3">
                  HP全体の背景画像を選びます。
                </num-instruction>

                <v-row class="mt-5 mb-7">
                  <v-col :cols="settingData.site_bg_image_url ? 10 : 12"
                    class="px-7 py-0"
                  >
                    <v-file-input
                      v-model="selectedImages['site_bg_image_url']"
                      accept="image/png, image/jpeg"
                      outlined
                      chips
                      show-size
                      prepend-icon="mdi-image"
                      label="HPコンテンツの背景画像"
                      hint="未設定の場合はサブカラーが背景色になります。"
                      persistent-hint
                      :rules="[valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 mt-n5"
                    v-if="settingData.site_bg_image_url"
                  >
                    <small>設定中の画像</small>
                    <v-img
                      max-height="70"
                      contain
                      :src="settingData.site_bg_image_url"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="pl-12 py-0 mt-n1 d-flex justify-end">
                    <v-btn
                      v-if="settingData.site_bg_image_url"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow('site_bg_image_url')"
                    >削除</v-btn>
                  </v-col>
                </v-row>

                <!-- ボタン -->
                <v-col cols="12" class="d-flex align-end">
                  <v-btn
                    :disabled="!exstsImagesToUpload"
                    depressed
                    color="primary"
                    @click="updateImages()"
                  >画像を登録</v-btn>
                  <!-- <icon-info icon="lightbulb-on-outline">
                    登録をお忘れなく
                  </icon-info> -->
                </v-col>
              </v-form>

              <!-- ******************************************** -->
              <!-- ******************************************** -->
              <!-- テーマ設定 -->
              <!-- ******************************************** -->
              <!-- ******************************************** -->
              <v-form ref="form-theme"
                class="mt-10"
              >
                <num-instruction number="4">続いてホームページのテーマカラーを選びます。</num-instruction>

                <!-- ******************************************** -->
                <!-- カラー設定 -->
                <v-row class="mt-3 mb-7">
                  <!-- primary -->
                  <v-col cols="4">
                    <p class="font-weight-bold"
                      :style="{color:settingData.primary_color}"
                    >メインカラー</p>
                    <small>メニューバーや見出し・タイトル等の背景色</small>
                    <v-color-picker
                      v-model="settingData.primary_color"
                      dot-size="28"
                      hide-canvas
                      hide-inputs
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="160"
                    ></v-color-picker>
                  </v-col>

                  <!-- accent -->
                  <v-col cols="4">
                    <p class="font-weight-bold"
                      :style="{color:settingData.accent_color}"
                    >アクセントカラー</p>
                    <small>メインカラーの中にあるボタンや文字の色</small>
                    <v-color-picker
                      v-model="settingData.accent_color"
                      dot-size="28"
                      hide-canvas
                      hide-inputs
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="160"
                    ></v-color-picker>
                  </v-col>

                  <!-- secondary -->
                  <v-col cols="4">
                    <p class="font-weight-bold"
                      :style="{color:settingData.secondary_color}"
                    >サブカラー</p>
                    <small>文章やコンテンツ等を囲む枠内の背景色</small>
                    <v-color-picker
                      v-model="settingData.secondary_color"
                      dot-size="28"
                      hide-canvas
                      hide-inputs
                      hide-mode-switch
                      mode="hexa"
                      show-swatches
                      swatches-max-height="160"
                    ></v-color-picker>
                  </v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- デモディスプレイ -->
                <!-- ******************************************** -->
                <v-row class="my-5">
                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" class="ml-4">
                      お店の印象となるメインカラーを起点に、アクセントカラーは対象的な色、
                      サブカラーはメインカラーを数トーン下げた色・もしくは背景画像に近い色、
                      を使うと調和の取れた見やすいホームページになります。
                    </icon-info>
                  </v-col>
                  <v-col cols="12">
                    <v-container
                      class="demo-display pa-0"
                      :style="{fontFamily:settingData.font}"
                    >
                      <!-- ヘッダー -->
                      <v-row no-gutters class="demo-header">
                        <v-col cols="12">
                          <v-card
                            flat tile
                            height="120"
                            :color="settingData.secondary_color || 'grey'"
                          >
                            <v-img
                              class="demo-header-bg demo-bg-img"
                              cover
                              position="center center"
                              height="inherit"
                              width="100vw"
                              :src="settingData.header_bg_image_url"
                            ></v-img>
                            <v-img
                              contain
                              position="center center"
                              height="inherit"
                              width="100vw"
                              :src="settingData.header_image_url"
                            ></v-img>
                          </v-card>
                        </v-col>
                      </v-row>
                      <!-- メニューバー -->
                      <v-row no-gutters class="demo-nav">
                        <v-col cols="12">
                          <v-card class="d-flex align-center justify-center"
                            flat tile
                            height="30"
                            :color="settingData.primary_color || ''"
                          >
                            <ul class="d-flex justify-center align-center"
                              :class="settingData.is_dark_theme ? 'font-white' : 'font-black'"
                            >
                              <li>ホーム</li>
                              <li
                                class="font-weight-bold"
                                :style="{color:settingData.accent_color}"
                              >キャスト</li>
                              <li>出勤表</li>
                              <li>システム</li>
                              <li>・・・</li>
                            </ul>
                          </v-card>
                        </v-col>
                      </v-row>
                      <!-- メイン -->
                      <v-sheet class="demo-main"
                        height="300"
                        :color="settingData.secondary_color || ''"
                      >
                        <v-img
                          class="demo-bg-img"
                          cover
                          position="center center"
                          height="inherit"
                          width="100vw"
                          :src="settingData.site_bg_image_url"
                        ></v-img>
                        <v-row no-gutters>
                          <v-col cols="2"></v-col>
                          <v-col cols="8" class="demo-contents py-4">
                            <v-row>
                              <v-col cols="3"
                                class="px-1 pb-0"
                                v-for="demo in demos"
                                :key="demo.name"
                              >
                                <v-card>
                                  <figure
                                    class="d-flex align-center justify-center"
                                    :style="{backgroundColor:settingData.secondary_color}"
                                    style="height:70px"
                                  >
                                    <v-icon
                                      large
                                      :color="settingData.primary_color || ''"
                                    >mdi-face-woman</v-icon>
                                  </figure>
                                  <v-sheet
                                    class="py-1 d-flex flex-column align-center"
                                    :color="settingData.primary_color || ''"
                                  >
                                    <small class="font-weight-bold"
                                      :class="settingData.is_dark_theme ? 'font-white' : 'font-black'"
                                    >{{ demo.name }}</small>
                                    <v-btn
                                      height="20"
                                      depressed small
                                      :color="settingData.accent_color || ''"
                                      :class="settingData.is_dark_theme ? 'font-white' : 'font-black'"
                                    >予約</v-btn>
                                  </v-sheet>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="2"></v-col>
                        </v-row>
                      </v-sheet>
                      <!-- フッター -->
                      <v-row no-gutters class="demo-footer">
                        <v-col cols="12">
                          <v-card class="d-flex align-center justify-center flex-grow-1"
                            flat tile
                            height="30"
                            :color="settingData.primary_color || ''"
                          >
                            <ul class="d-flex justify-center align-center"
                              :class="settingData.is_dark_theme ? 'font-white' : 'font-black'"
                            >
                              <v-icon small>mdi-home</v-icon>
                              <v-icon
                                small
                                :color="settingData.accent_color || ''"
                              >mdi-face-woman</v-icon>
                              <v-icon small>mdi-calendar-clock</v-icon>
                              <v-icon small>mdi-book-open-variant</v-icon>
                              <v-icon small>mdi-dots-horizontal</v-icon>
                            </ul>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- フォント -->
                <num-instruction number="5">最後に、文字のフォントと色を白黒から選びます。</num-instruction>

                <icon-info icon="lightbulb-on-outline" class="ml-5 mb-7">
                  カラフルな背景に白色ゴシックテキストでポップ＆お洒落な印象に、
                  ダークで落ち着いた背景に白色明朝体テキストで高級感のある印象に、などなど。。<br />
                </icon-info>

                <!-- ダーク or ライト -->
                <v-row>
                  <v-col cols="0" md="2"></v-col>

                  <v-col cols="6" md="4">
                    <v-switch
                      class="my-2"
                      v-model="settingData.is_dark_theme"
                      inset
                      prepend-icon="mdi-alpha-f-box"
                      label="文字を白にする"
                    ></v-switch>
                  </v-col>

                  <!-- ******************************************** -->
                  <!-- フォント -->
                  <v-col cols="6" md="4">
                    <v-select
                      v-model="settingData.font"
                      label="フォントの種類"
                      :items="fontList"
                      item-text="label"
                      item-value="font"
                      item-color="primary"
                    ></v-select>
                  </v-col>
                  <v-col cols="0" md="2"></v-col>
                </v-row>

                <!-- ******************************************** -->
                <!-- 更新ボタン -->
                <v-row class="mt-5 pl-3">
                  <num-instruction number="6">
                    お疲れさまです！デザインを登録したら完成です<v-icon>mdi-party-popper</v-icon>
                    素敵なホームページを作って売上をアップしましょう<v-icon>mdi-emoticon-wink-outline</v-icon>
                  </num-instruction>
                  <v-col cols="12" class="ml-3">
                    <v-btn
                      depressed
                      color="primary"
                      @click="updateTheme()"
                    >デザインを登録</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      header
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import IconInfo from '@/components/_IconInfo.vue'
import NumberInstruction from '@/components/_NumberInstruction.vue'
import BannerHint from '@/components/_BannerHint.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'num-instruction': NumberInstruction,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      font: 'Avenir',
      themeType: 'dark',
      settingData: { primary_color: '', secondary_color: '', accent_color: '' },
      selectedImages: {},
      demos: [{name:'A子(20)'},{name:'B子(25)'},{name:'C子(23)'},{name:'D子(21)'},{name:'E子(28)'},{name:'F子(20)'}],
      fontList: [{label:'ゴシック', font: 'Meiryo'}, {label:'明朝体', font: 'serif'}],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    exstsImagesToUpload() {
      for (let key in this.selectedImages) {
        if (this.selectedImages[key]) return true
      }
      return false
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getSiteSetting(),
      ])

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getSiteSetting() {
      return this.adminApi.getReqWithAuth('site-setting/').then( data => {
        if (!data) return

        this.settingData = data
      })
    },

    //**************************************************
    //登録＆更新
    //**************************************************
    updateTheme() {
      if (!this.$refs['form-theme'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'update/site-setting/'
      const formData = new FormData()
      formData.append('header_image_url', this.settingData.header_image_url)
      formData.append('header_image_mobile_url', this.settingData.header_image_mobile_url)
      formData.append('header_bg_image_url', this.settingData.header_bg_image_url)
      formData.append('header_bg_image_mobile_url', this.settingData.header_bg_image_mobile_url)
      formData.append('site_bg_image_url', this.settingData.site_bg_image_url)
      formData.append('is_dark_theme', this.settingData.is_dark_theme)
      formData.append('primary_color', this.settingData.primary_color)
      formData.append('secondary_color', this.settingData.secondary_color)
      formData.append('accent_color', this.settingData.accent_color)
      formData.append('font', this.settingData.font)
      formData.append('primary_font_color', this.settingData.primary_font_color)
      formData.append('secondary_font_color', this.settingData.secondary_font_color)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //画像登録＆更新
    //**************************************************
    updateImages() {
      if (!this.$refs['form-images'].validate() || !this.exstsImagesToUpload) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData()

      //更新カラム名の生成と画像をフォームへセット
      let update_columns = ''
      for (let key in this.selectedImages) {
        if (this.selectedImages[key]) {
          update_columns += (update_columns ? ',' : '') + key
          formData.append(key, this.selectedImages[key])
        }
      }
      formData.append('update_columns', update_columns)

      this.adminApi.apiReqWithData('PUT', 'update/site-setting/images/', formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        // 画像選択を削除
        for (let key in this.selectedImages) {
          delete this.selectedImages[key]
        }
        // 現在設定中を更新
        const key = update_columns.split(',')
        for (let i = 0; i < response.length; i++) {
          this.settingData[key[i]] = response[i]
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(columnName) {
      const apiPath = 'delete/site-setting/column/' + columnName

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.settingData[columnName] = ''

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
.demo-display {
  overflow-y: hidden;
  position: relative;
  height: 360px;
  max-width: 500px;
  border: 1px solid;
}
.theme--dark .demo-display {
  border-color: var(--content-border-dark);
}
.demo-display ul {
  list-style: none;
}
.demo-bg-img {
  position: absolute;
}
.demo-nav li {
  font-size: .7em;
  margin: 0 5px;
}
.demo-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.demo-footer .v-icon {
  margin: 0 5px;
}
.font-black i, .font-white {
  color: white;
}
.font-black i, .font-black {
  color: black;
}
</style>
